import * as React from "react"

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { Trash } from 'react-bootstrap-icons';
import { PlusLg } from 'react-bootstrap-icons';

import { Eye } from 'react-bootstrap-icons';
import { EyeSlash } from 'react-bootstrap-icons';

const EXTRA_PAYMENT_MODES = [
  { key: 'one-time', value: 'Jednorazowa' },
  { key: 'monthly', value: 'Co miesiąc' },
  { key: 'quarterly', value: 'Co kwartał' },
  { key: 'annually', value: 'Co rok' }
];

const ExtraPayments = ({ formData, handleFormDataChange }) => {
  const [localForm, setLocalForm] = React.useState({
    mode: 'one-time',
    fromMonth: 0,
    repetitions: 0,
    amount: 0,
    enabled: true
  });

  const handleSubmit = () => {
    handleFormDataChange(
      'extraPayments',
      formData.extraPayments.concat(localForm)
    );
  }

  const switchStatus = (at, enabled) => {
    var newExtraPayments = formData.extraPayments;
    newExtraPayments[at].enabled = enabled;

    handleFormDataChange(
      'extraPayments',
      newExtraPayments
    );
  }

  const removeExtraPayment = (at) => {
    const beforeDeletePrompt = 'Czy na pewno chcesz usunąć nadpłatę?';

    if (!window.confirm(beforeDeletePrompt)) {
      return;
    }

    handleFormDataChange(
      'extraPayments',
      formData.extraPayments.filter((_r, idx) => idx !== at)
    );
  }

  return (
    <>
      <Form
        className="mb-4"
        onSubmit={e => { e.preventDefault(); handleSubmit(); }}
      >
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="3">
            Rodzaj nadpłaty
          </Form.Label>
          <Col sm="9" >
            <Form.Select
              className="bg-white"
              aria-label="Rodzaj nadpłaty"
              onChange={(e) => setLocalForm({...localForm, mode: e.target.value})}
            >
              {EXTRA_PAYMENT_MODES.map(r => (
                <option key={r.key} value={r.key}>{r.value}</option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="3">
            Miesiąc wpłaty
          </Form.Label>
          <Col sm="9">
            <Form.Select
              className="bg-white"
              aria-label="Od miesiąca"
              onChange={(e) => setLocalForm({...localForm, fromMonth: parseFloat(e.target.value)})}
            >
              {[...Array(formData.years * 12).keys()].map(month => (
                <option key={month} value={month}>{month + 1}</option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>

        { localForm.mode !== 'one-time' ? (
          <Form.Group as={Row} className="mb-2">
            <Form.Label column sm="3">
              Liczba powtórzeń
            </Form.Label>
            <Col sm="9">
              <Form.Select
                className="bg-white"
                aria-label="Od miesiąca"
                onChange={(e) => setLocalForm({...localForm, repetitions: parseFloat(e.target.value) })}
              >
                {[...Array(formData.years * (localForm.mode === 'monthly' ? 12 : (localForm.mode === 'quarterly' ? 4 : 1))).keys()].map(month => (
                  <option key={month} value={month}>{month + 1}</option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
        ) : '' }

        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="3">
            Kwota
          </Form.Label>
          <Col sm="9">
            <Form.Control
              className="bg-white"
              type="number"
              min="0"
              step="1"
              required
              onChange={(e) => setLocalForm({...localForm, amount: parseFloat(e.target.value) })}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="3"></Form.Label>
          <Col sm="9">
            <Button
              className="float-end"
              variant="success"
              type="submit"
            >
              <PlusLg /> Dodaj
            </Button>
          </Col>
        </Form.Group>
      </Form>

      <hr />

      <Table className="table-sm">
        <thead>
          <tr>
            <td>#</td>
            <td>Rodzaj nadpłaty</td>
            <td>Miesiąc wpłaty</td>
            <td>Liczba powtórzeń</td>
            <td>Kwota</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {formData && formData.extraPayments.map((row, index) => (
            <tr key={ index }>
              <td>{ index + 1 }</td>
              <td>{ EXTRA_PAYMENT_MODES.find(r => row.mode === r.key).value }</td>
              <td>{ row.fromMonth + 1 }</td>
              <td>{ row.mode === 'one-time' ? '-' : row.repetitions + 1 }</td>
              <td>{ row.amount }</td>
              <td>
                {row.enabled ? (
                  <Eye
                    className="me-3"
                    role="button"
                    onClick={() => switchStatus(index, false)}
                  />
                ) : (
                  <EyeSlash
                    className="me-3"
                    role="button"
                    onClick={() => switchStatus(index, true)}
                  />
                )}
                <Trash
                  role="button"
                  onClick={() => removeExtraPayment(index)}
                />
              </td>
            </tr>
          ))}

          {formData && formData.extraPayments.length === 0 ? (
            <tr>
              <td colSpan="6">
                <small className="text-muted">Użyj powyższego fomularza, aby dodać nadpłaty.</small>
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </>
  )
}

export default ExtraPayments

import * as React from "react"

import Button from 'react-bootstrap/Button';

const OtherSettings = ({ formData, handleFormDataChange }) => {
  return (
    <>
      <div className="d-grid gap-2">
        <Button variant="outline-danger" onClick={() => window.location = '/' }>
          Resetuj ustawienia
        </Button>
      </div>
    </>
  )
}

export default OtherSettings

import * as React from "react"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from "gatsby"

const Footer = () => (
  <footer className="pb-3">
    <Row>
      <Col>
        <ul className="list-unstyled">
          <li className="float-end"><a href="#top">Powrót na górę</a></li>
          <li><Link to="/">Strona główna</Link></li>
          <li><Link to="/kontakt">Kontakt</Link></li>
        </ul>
      </Col>
    </Row>
  </footer>
)

export default Footer

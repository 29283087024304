import * as React from "react"

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { Trash } from 'react-bootstrap-icons';
import { PlusLg } from 'react-bootstrap-icons';

import { Eye } from 'react-bootstrap-icons';
import { EyeSlash } from 'react-bootstrap-icons';

const InterestRateChanges = ({ formData, handleFormDataChange }) => {
  const [localForm, setLocalForm] = React.useState({
    fromMonth: 0,
    interestRate: 4.2,
    enabled: true
  });

  const handleSubmit = () => {
    handleFormDataChange(
      'interestRateChanges',
      formData.interestRateChanges.concat(localForm)
    );
  }

  const switchStatus = (at, enabled) => {
    var newRow = formData.interestRateChanges;
    newRow[at].enabled = enabled;

    handleFormDataChange('interestRateChanges', newRow);
  }

  const removeRow = (at) => {
    const beforeDeletePrompt =
      'Czy na pewno chcesz usunąć zmianę oprocentowania?';

    if (!window.confirm(beforeDeletePrompt)) {
      return;
    }

    handleFormDataChange(
      'interestRateChanges',
      formData.interestRateChanges.filter((_r, idx) => idx !== at)
    );
  }

  return (
    <>
      <Form
        className="mb-4"
        onSubmit={e => { e.preventDefault(); handleSubmit(); }}
      >
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="3">
            Od miesiąca
          </Form.Label>
          <Col sm="9">
            <Form.Select
              className="bg-white"
              aria-label="Od miesiąca"
              onChange={(e) => setLocalForm({...localForm, fromMonth: parseFloat(e.target.value)})}
            >
              {[...Array(formData.years * 12).keys()].map(month => (
                <option key={month} value={month}>{month + 1}</option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="3">
            Oprocentowanie
          </Form.Label>
          <Col sm="9">
            <Form.Control
              className="bg-white"
              type="number"
              min="0.01"
              max="15"
              step="0.01"
              required
              onChange={(e) => setLocalForm({...localForm, interestRate: parseFloat(e.target.value) })}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="3"></Form.Label>
          <Col sm="9">
            <Button
              className="float-end"
              variant="success"
              type="submit"
            >
              <PlusLg /> Dodaj
            </Button>
          </Col>
        </Form.Group>
      </Form>

      <hr />

      <Table className="table-sm">
        <thead>
          <tr>
            <td>#</td>
            <td>Od miesiąca</td>
            <td>Oprocentowanie</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {formData && formData.interestRateChanges.map((row, index) => (
            <tr key={ index }>
              <td>{ index + 1 }</td>
              <td>{ row.fromMonth }</td>
              <td>{ row.interestRate }</td>
              <td>
                {row.enabled ? (
                  <Eye
                    className="me-3"
                    role="button"
                    onClick={() => switchStatus(index, false)}
                  />
                ) : (
                  <EyeSlash
                    className="me-3"
                    role="button"
                    onClick={() => switchStatus(index, true)}
                  />
                )}
                <Trash
                  role="button"
                  onClick={() => removeRow(index)}
                />
              </td>
            </tr>
          ))}

          {formData && formData.interestRateChanges.length === 0 ? (
            <tr>
              <td colSpan="6">
                <small className="text-muted">Użyj powyższego fomularza, aby dodać zmiany oprocentowania.</small>
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </>
  )
}

export default InterestRateChanges

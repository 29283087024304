
const calculateInstalments = (formValues) => {
  const amount = formValues.amount;
  const instalmentsTotal = formValues.years * 12;

  let remainingPrincipal = amount;
  let instalments = {
    all: [],
    sumByYears: [],
    totals: []
  };

  var totalsByYear = [0, 0, 0];
  var totals = [0, 0, 0];

  for(var i = 0; i < instalmentsTotal; i++) {
    var interestRate = (
      getCustomInterestRateForMonth(formValues.interestRateChanges, i) || formValues
    ).interestRate;
    var monthlyInterestRate = interestRate / 100.0 / 12;

    var monthlyInstalmentAmount =
      remainingPrincipal * (
        (monthlyInterestRate * (1 + monthlyInterestRate) ** (instalmentsTotal - i)) /
        (((1 + monthlyInterestRate) ** (instalmentsTotal - i)) - 1)
      );

    var interestInstalment = remainingPrincipal * monthlyInterestRate;
    var capitalInstalment = monthlyInstalmentAmount - interestInstalment;

    var extraPayment = getExtraPaymentsForMonth(formValues.extraPayments, i);
    var principalReduction = capitalInstalment + extraPayment;

    if (principalReduction >= remainingPrincipal ) {
      var remainingExtraPayment =
        (remainingPrincipal - capitalInstalment > 0) ? remainingPrincipal - capitalInstalment : 0;

      totalsByYear = [
        totalsByYear[0] + interestInstalment,
        totalsByYear[1] + capitalInstalment,
        totalsByYear[2] + remainingExtraPayment
      ];

      instalments.sumByYears.push(totalsByYear);
      totals = [
        totals[0] + totalsByYear[0],
        totals[1] + totalsByYear[1],
        totals[2] + totalsByYear[2]
      ];
      instalments.totals = totals;
      instalments.all.push(
        [interestInstalment, capitalInstalment, remainingExtraPayment]
      );
      break;
    }

    totalsByYear = [
      totalsByYear[0] + interestInstalment,
      totalsByYear[1] + capitalInstalment,
      totalsByYear[2] + extraPayment
    ];

    if ((i + 1) % 12 === 0) {
      instalments.sumByYears.push(totalsByYear);

      totals = [
        totals[0] + totalsByYear[0],
        totals[1] + totalsByYear[1],
        totals[2] + totalsByYear[2]
      ];
      totalsByYear = [0, 0, 0];
    }

    instalments.all.push([interestInstalment, capitalInstalment, extraPayment]);
    remainingPrincipal -= principalReduction;
  }

  instalments.totals = totals;

  // console.log(formValues);
  // console.log(instalments);
  return instalments;
}

const getExtraPaymentsForMonth = (extraPayments, month) => {
  return extraPayments.reduce((sum, r) => {
    if (r.enabled === false) {
      return sum;
    }
    if (r.fromMonth > month) {
      return sum;
    }

    var intervalDivider;
    switch (r.mode) {
      case 'monthly':
        intervalDivider = 1;
        break;
      case 'quarterly':
        intervalDivider = 4;
        break;
      case 'annually':
        intervalDivider = 12;
        break;
      default:
        intervalDivider = 9999;
    }


    if (
      (((month - r.fromMonth) % intervalDivider) === 0) &&
      ((intervalDivider * r.repetitions + r.fromMonth) >= month)
    ) {
      return sum + r.amount;
    }

    return sum;
  }, 0);

}

const getCustomInterestRateForMonth = (interestRateChanges, month) => {
  return interestRateChanges.reverse().find(r => {
    return ((r.fromMonth <= month) && r.enabled);
  });
}

export default calculateInstalments;

import * as React from "react"

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import SEO from "../Seo"

import axios from 'axios';

const Contact = () => {
  const [serverState, setServerState] = React.useState({});

  const handleSubmit = async (e) => {
    const bodyPayload = {
      message: {
        topic: e.target.elements['message-topic'].value,
        author: e.target.elements['message-author'].value,
        content: e.target.elements['message-content'].value
      }
    };

    try {
      await axios({
        url: 'https://europe-central2-kredythipotecznyinfo.cloudfunctions.net/contact-form',
        method: 'POST',
        data: bodyPayload
      });

      setServerState({
        ok: true,
        message: 'Wiadomość została wysłana!',
      });

    } catch (error) {
      if (error.response) {
        console.log('Server responded with non 2xx code: ', error.response.data);
      } else if (error.request) {
        console.log('No response received: ', error.request);
      } else {
        console.log('Error setting up response: ', error.message);
      }

      setServerState({
        ok: false,
        message: 'Błąd, spóbuj ponownie później.',
      });
    }
  };

  return (
    <>
      <SEO title="Kontakt" />

      <Row className="mt-3 mb-5">
        <Col md={{ span: 6, offset: 3}}>
          <h2>Kontakt</h2>

          {(serverState && serverState.ok !== undefined) ? (
            <Alert variant={serverState.ok ? 'success' : 'danger'}>
              { serverState.message }
            </Alert>
          ) : null}

          <Form
            className="mt-3 mb-5"
            onSubmit={e => { e.preventDefault(); handleSubmit(e); }}
          >
            <Form.Group className="mb-3">
              <Form.Label>Temat wiadomości</Form.Label>
              <Form.Select name="message-topic" aria-label="Temat wiadomości">
                <option value="not-selected">---</option>
                <option value="bug-report">Błąd na stronie</option>
                <option value="cooperation-proposal">Propozycja współpracy</option>
                <option value="other">Inne</option>
              </Form.Select>
              <Form.Text className="text-muted">
                Temat określający charakter wiadomości.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Adres email</Form.Label>
              <Form.Control name="message-author" type="email" placeholder="Email" required />
              <Form.Text className="text-muted">
                Adres, na który ma być wysłana odpowiedź.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Wiadomość</Form.Label>
              <Form.Control name="message-content" as="textarea" rows={6} required />
            </Form.Group>

            <div className="d-grid gap-2">
              <Button variant="primary" type="submit">
                Wyślij
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default Contact

import * as React from "react"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import { Sliders } from 'react-bootstrap-icons';

import MainFormModal from "./main_form_modal";

import formattedNumber from '../../utils/number_formatting'

const MainForm = ({
  formData, handleFormDataChange, showMainFormModal, setShowMainFormModal
}) => {
  return (
    <div className="main-settings">
      <Row>
        <Col md={{ span: 4 }}>
          <InputGroup className="mb-2">
            <Form.Control
              size="lg"
              type="text"
              name="amount"
              value={formData.amount}
              onChange={e => { handleFormDataChange(e.target.name, e.target.value) }}
              className="text-left"
            />
            <InputGroup.Text>zł</InputGroup.Text>
          </InputGroup>
        </Col>
        <Col md={{ span: 4 }}>
          <InputGroup className="mb-2">
            <Form.Control
              size="lg"
              type="text"
              name="years"
              value={formData.years}
              onChange={e => { handleFormDataChange(e.target.name, e.target.value) }}
              className="text-right"
            />
            <InputGroup.Text>lat</InputGroup.Text>
          </InputGroup>
        </Col>
        <Col md={{ span: 4 }}>
          <InputGroup className="mb-2">
            <Form.Control
              size="lg"
              type="text"
              name="interestRate"
              value={formData.interestRate}
              onChange={e => { handleFormDataChange(e.target.name, e.target.value) }}
              className="text-right"
            />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row className="">
        <Col md={{ span: 4 }}>
          <Form.Range
            name="amount"
            value={formData.amount}
            onChange={e => { handleFormDataChange(e.target.name, e.target.value) }}
            min="50000" max="2500000" step="1000" />
        </Col>
        <Col md={{ span: 4 }}>
          <Form.Range
            name="years"
            value={formData.years}
            onChange={e => { handleFormDataChange(e.target.name, e.target.value) }}
            min="1" max="50" step="1" />
        </Col>
        <Col md={{ span: 4 }}>
          <Form.Range
            name="interestRate"
            value={formData.interestRate}
            onChange={e => { handleFormDataChange(e.target.name, e.target.value) }}
            min="0.01" max="15" step="0.01" />
        </Col>
      </Row>
      <Row className="mt-1 mb-4 align-items-end">
        <Col md={{ span: 12 }}>
          <div className="d-grid gap-2">
            <Button
              onClick={() => setShowMainFormModal(true)}
              variant="primary"
              className=""
              role="button"
            >
              <Sliders className="me-1" /> Dodaj nadpłatę lub zmianę oprocentowania
            </Button>
          </div>
        </Col>

      </Row>
      <MainFormModal
        show={showMainFormModal}
        handleClose={() => setShowMainFormModal(false)}
        formData={formData}
        handleFormDataChange={handleFormDataChange} />
    </div>
  )
}

export default MainForm

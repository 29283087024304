import * as React from "react"

import Container from 'react-bootstrap/Container';
import { HouseDoorFill } from 'react-bootstrap-icons';
import BsNavbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { ShareFill } from 'react-bootstrap-icons';
import { EnvelopeFill } from 'react-bootstrap-icons';

import { Link } from 'gatsby'

const Navbar = () => {
  return (
    <BsNavbar className="navbar-dark bg-primary" expand="lg">
      <Container fluid="md">
        <BsNavbar.Brand>
          <Link to="/">
            <HouseDoorFill className="fs-2 mr-2" style={{ 'color': 'white' }} />
          </Link>
        </BsNavbar.Brand>

        <BsNavbar.Brand style={{ marginTop: '-10px' }}>
          <h1 style={{ fontSize: '1.25rem', letterSpacing: '0.05em' }}>KredytHipoteczny.info
          <small
            className="mt-1"
            style={{ fontSize: '0.75rem', fontWeight: 300 }}
          >
            Kalkulator zmienności rat kredytu hipotecznego
          </small>
          </h1>
        </BsNavbar.Brand>

        <BsNavbar.Toggle aria-controls="basic-navbar-nav" />

        <BsNavbar.Collapse className="ml-auto justify-content-end">
          <Nav className="">
            <Link to="/kontakt" className="me-3 nav-link" activeClassName="active">
              <EnvelopeFill className="me-1" /> Kontakt
            </Link>
            <Nav.Link href="#home">
              <ShareFill className="me-1" /> Skopiuj link do symulacji
            </Nav.Link>
          </Nav>
        </BsNavbar.Collapse>
      </Container>
    </BsNavbar>
  )
}

export default Navbar

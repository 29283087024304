import React from "react"
import { Router } from "@reach/router"

import Layout from "../components/Layout"
import Calculator from "../components/Calculator"
import Contact from "../components/Contact"

const App = () => (
  <Layout>
    <Router>
      <Calculator default path="/" />
      <Contact path="/kontakt" />
    </Router>
  </Layout>
)

export default App

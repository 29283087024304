import * as React from "react"

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const InstalmentsChart = ({ installments, chartMode }) => {

  const options = {
    plugins: {
      title: {
        // display: true,
        // text: 'Chart.js Bar Chart - Stacked',
      },
      legend: {
        position: 'bottom'
      }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  var dataPoints = [];
  var labels = [];

  if (chartMode === 'years') {
    dataPoints = installments.sumByYears;
    labels = [...Array(dataPoints.length).keys()].map(x => { var year = x + 1; return 'Rok ' + year });
  } else {
    dataPoints = installments.all;
    labels = [...Array(dataPoints.length).keys()].map(x => x + 1);
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Rata, część odsetkowa',
        data: dataPoints.map(r => r[0]),
        backgroundColor: '#cdc1e0',
      },
      {
        label: 'Rata, część kapitałowa',
        data: dataPoints.map(r => r[1]),
        backgroundColor: '#b8eacc',
      },
      {
        label: 'Nadpłata',
        data: dataPoints.map(r => r[2]),
        backgroundColor: '#b3e1f5',
      },
    ],
  };

  return (
    <>
      <Bar options={options} data={data} />
      <ButtonGroup className="float-end" size="sm">
        <ToggleButton
          key="1"
          id={`radio-${1}`}
          type="radio"
          variant="outline-primary"
          name="chartMode"
          value="years"
          checked={ true /* formData.chartMode === "years"  */}
          onChange={ () => {} /* handleFormDataChange */ }>
          Lata
        </ToggleButton>
        <ToggleButton
          key="2"
          id={`radio-${2}`}
          type="radio"
          variant="outline-primary"
          name="chartMode"
          value="instalments"
          checked={ false /* formData.chartMode === "instalments"  */}
          onChange={ () => {} /* handleFormDataChange */}>
          Raty
        </ToggleButton>
      </ButtonGroup>
    </>
  )
}

export default InstalmentsChart

import * as React from "react"

import MainForm from './main_form';
import InstallmentsTable from "./installments_table";
import InstallmentsStats from "./installments_stats";
import InstalmentsChart from "./instalments_chart";
import SEO from "../Seo";

import calculateInstalments from './mortgage_helper'

import debounce from "lodash.debounce";

import { navigate } from 'gatsby';
import { useLocation } from "@reach/router"
import { Link } from "gatsby"

const DEFAULT_FORM_CONFIGURATION = {
  amount: 450000,
  years: 30,
  interestRate: 4.2,
  chartMode: 'years',
  // chartMode: 'instalments',
  interestRateChanges: [],
  extraPayments: []
}

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = React.useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
};

const Calculator = () => {
  const [formData, setFormData] = React.useState(DEFAULT_FORM_CONFIGURATION);
  const [computedInstalments, setComputedInstalments] = React.useState({
    all: [],
    sumByYears: [],
    totals: []
  });
  const [showMainFormModal, setShowMainFormModal] = React.useState(false);
  const { search } = useLocation();

  const recalculate = React.useCallback(
    debounce(formData => {
      const formConfiguration = btoa(JSON.stringify(formData));
      setComputedInstalments(calculateInstalments(formData));
      navigate('?config=' + formConfiguration, { replace: true });
      window && window.gtag && window.gtag("event", "configurationChange", { config: formConfiguration })
    }, 250),
    []
  );

  const handleFormDataChange = (fieldName, newValue) => {
    const newState = { ...formData, [fieldName]: newValue };
    setFormData(newState);
    recalculate(newState);
  };

  useConstructor(() => {
    const searchParmas = new URLSearchParams(search);
    const configQueryParam = searchParmas.get('config');
    var formConfiguration = DEFAULT_FORM_CONFIGURATION;

    if (configQueryParam) {
      const formDataFromParams = JSON.parse(atob(configQueryParam));
      if (formDataFromParams !== formConfiguration) {
        formConfiguration = formDataFromParams;
      }
    }

    setFormData(formConfiguration);
    setComputedInstalments(calculateInstalments(formConfiguration));
  });

  return (
    <>
      <SEO title="Symulacja" />
      <MainForm
        formData={formData}
        handleFormDataChange={handleFormDataChange}
        showMainFormModal={showMainFormModal}
        setShowMainFormModal={setShowMainFormModal} />

      <InstallmentsStats
        installments={ computedInstalments } />

      <InstalmentsChart
        installments={ computedInstalments }
        chartMode={formData.chartMode} />

      <InstallmentsTable
        installments={ computedInstalments } />

      <p className="text-muted text-center py-5 px-1">
        <small>
          Powyższa symulacja bazuje na <a href="https://en.wikipedia.org/wiki/Mortgage_calculator#Monthly_payment_formula" target="_blank">ogólnej formule</a> kredytu
          hipotecznego i ma jedynie ma charakter orientacyjny. Ostateczny harmonogram rat i suma kwoty do spłacania może się różnić w zależności od kredytodawcy. W przypadku pytań lub uwag skorzystaj proszę z <Link to="/kontakt">formularza kontaktowego</Link>.
        </small>
      </p>
    </>
  )
}

export default Calculator

import * as React from "react"

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Badge from 'react-bootstrap/Badge';

import ExtraPayments from "./extra_payments";
import InterestRateChanges from "./interest_rate_changes";
import OtherSettings from "./other_settings";

const MainFormModal = ({ show, handleClose, formData, handleFormDataChange }) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Modyfikacje kredytu</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Tabs fill defaultActiveKey="nadplata" className="mb-3">
          <Tab
            eventKey="nadplata"
            title={
              <>
                Nadpłata
                {formData.extraPayments.length > 0 ? (
                  <Badge pill variant='light' className="ms-2">{formData.extraPayments.length}</Badge>
                ) : ''}
              </>
            }
          >
            <ExtraPayments
              formData={formData}
              handleFormDataChange={handleFormDataChange}
            />
          </Tab>
          <Tab
            eventKey="oprocentowanie"
            title={
              <>
                Oprocentowanie
                {formData.interestRateChanges.length > 0 ? (
                  <Badge pill variant='light' className="ms-2">{formData.interestRateChanges.length}</Badge>
                ) : ''}
              </>
            }
          >
            <InterestRateChanges
              formData={formData}
              handleFormDataChange={handleFormDataChange}
            />
          </Tab>
          {/* <Tab eventKey="dodatkowe-oplaty" title="Dodatkowe opłaty">
            abc
          </Tab> */}
          <Tab eventKey="inne" title="Inne">
            <OtherSettings
              formData={formData}
              handleFormDataChange={handleFormDataChange}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Zamknij
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MainFormModal

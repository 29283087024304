import * as React from "react"

import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import formattedNumber from '../../utils/number_formatting'

const InstallmentsStats = ({ installments }) => {
  const stats = [
    {
      variant: 'warning',
      number: installments.totals[0] && (installments.totals[0] + installments.totals[1] + installments.totals[2]),
      text: 'Suma do spłaty'
    },
    {
      variant: 'primary',
      number: installments.totals[0] && installments.totals[0],
      text: 'Suma odsetek'
    },
    {
      variant: 'info',
      number: installments.totals[2] && installments.totals[2],
      text: 'Suma nadpłat'
    }
  ];
  return (
    <Row>
      {stats.map((r, idx) => (
        <Col key={idx} md={{ span: 4 }}>
          <Alert variant={ r.variant } className="text-end py-2 px-2 border-0">
            <h2 className="my-0">
              { r.number && formattedNumber(r.number) }
            </h2>
            <span>{ r.text }</span>
          </Alert>
        </Col>)
      )}
    </Row>
  )
}

export default InstallmentsStats

import React from "react"

import Container from 'react-bootstrap/Container';

import Navbar from "./navbar"
import Footer from "./footer"
import SEO from "../Seo"

import "./styles.scss"

const Layout = ({ children }) => {
  return(
    <React.StrictMode>
      <SEO />
      <main>
        <Navbar />

        <Container
          fluid="md"
          className="border shadow-sm py-3"
          style={{ 'backgroundColor': 'white' }}
        >
          {children}

          <Footer />
        </Container>
      </main>
    </React.StrictMode>
  )
}

export default Layout

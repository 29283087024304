import * as React from "react"

import Table from 'react-bootstrap/Table';

import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Accordion from 'react-bootstrap/Accordion';

import formattedNumber from '../../utils/number_formatting'

const CustomToggle = ({ children, eventKey }) => {
  return (
    <button
      type="button"
      onClick={useAccordionButton(eventKey)}
    >
      {children}
    </button>
  );
}

const TableBody = ({ installments }) => (
  <tbody>
    {installments.sumByYears.map((row, index) => (
      <React.Fragment key={ index }>
        <tr key={ index + '-sum-by-years' }>
          <td width="6%">
            <CustomToggle eventKey={ index }>
              >
            </CustomToggle>
          </td>
          <td width="6%">{ index + 1 }</td>
          <td width="22%">{ formattedNumber(row[0]) }</td>
          <td width="22%">{ formattedNumber(row[1]) }</td>
          <td width="22%">{ formattedNumber(row[2]) }</td>
          <td width="22%">{ formattedNumber(row[0] + row[1] + row[2]) }</td>
        </tr>
        <tr key={ index + '-all' }>
          <td colSpan="6" className="hiddenRow">
            <Accordion.Collapse eventKey={ index }>
              <Table className="m-0" size="sm">
                <tbody>
                  {installments.all.slice(index * 12, (index * 12) + 12).map((monthRow, monthIndex) => (
                    <tr key={ monthIndex }>
                      <td width="6%">{ index * 12 + 1 + monthIndex }</td>
                      <td width="6%">{ index + 1 }</td>
                      <td width="22%">{ formattedNumber(monthRow[0]) }</td>
                      <td width="22%">{ formattedNumber(monthRow[1]) }</td>
                      <td width="22%">{ formattedNumber(monthRow[2]) }</td>
                      <td width="22%">{ formattedNumber(monthRow[0] + monthRow[1] + monthRow[2]) }</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Accordion.Collapse>
          </td>
        </tr>
      </React.Fragment>
    ))}
    {installments.totals[0] ? (
      <tr>
        <td width="6%">-</td>
        <td width="6%">-</td>
        <td width="22%">
          <strong>{ formattedNumber(installments.totals[0]) }</strong>
        </td>
        <td width="22%">
          <strong>{ formattedNumber(installments.totals[1]) }</strong>
        </td>
        <td width="22%">
          <strong>{ formattedNumber(installments.totals[2]) }</strong>
        </td>
        <td width="22%">
          <strong>{ formattedNumber(installments.totals[0] + installments.totals[1] + installments.totals[2]) }</strong>
        </td>
      </tr>
    ) : null}
  </tbody>
)

const InstallmentsTable = ({ installments }) => {
  return (
    <Accordion alwaysOpen>
      <h3>Harmonogram rat</h3>
      <Table className="text-end">
        <thead>
          <tr>
            <td>Miesiąc</td>
            <td>Rok</td>
            <td>Odsetki</td>
            <td>Kapitał</td>
            <td>Nadpłaty</td>
            <td><strong>Suma</strong></td>
          </tr>
        </thead>
        <TableBody installments={installments} />
      </Table>
    </Accordion>
  )
}

export default InstallmentsTable
